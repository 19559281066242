import React from "react"
import { ButtonDegrade, CardElevation, TitleIcon, InputNip } from '@findep/mf-landings-core'
import MobileFriendly from '@material-ui/icons/MobileFriendly';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import ButtonDegradeFirma from '../elements/ButtonDegradefirma'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Backdrop, Snackbar, FormHelperText } from "@material-ui/core"
import Alert from '@material-ui/lab/Alert';
import { navigate } from "../../helpers/queryNavigate"
// const backDropCss = css`
//     z-index: 1000;
// `

import FirmaDigital from './FirmaDigital'
class VerificacionTelefonica extends FirmaDigital {
  // constructor(props) {
  //   super(props)
  // }

  eventoContinuar = () => {
    const flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    flujo.tubo = { ...flujo.tubo, VerifyIdentityWithAutonomation: 'InProgress' }
    navigate(`/tu-proceso`)
  }

  render() {
    const telefono = this.state.mobileNumber
    let texto = this.state.count > 0 ? `   REACTIVANDO EN:  ${this.state.count}` : `REENVIAR CÓDIGO AL: ${telefono}`
    let loader = CircularProgress
    return (
      <>
        <Grid container justify="center">
          <Grid item style={{ textAlign: "center" }}>
            <TitleIcon icon={MobileFriendly} title='Verificación telefónica' textColor />
          </Grid>
        </Grid>
        <br />

        <CardElevation>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Acabamos de enviarte un código de un sólo uso a tu dispositivo.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ButtonDegradeFirma disabled={!this.state.timeout} id="firma-digital-enviar-codigo-button" disabled={!this.state.timeout} icon={this.state.count > 0 ? loader : MobileFriendly} textButton={texto} onClick={this.eventoEnviarMensaje} />
              {/* <br />
              {
                this.state.timeout &&
                <FormHelperText css={this.state.helpTxt} ><p onClick={() => { this.eventoLink() }} style={{ color: "#bf0000", cursor: "pointer" }} >No he recibido ningún SMS</p></FormHelperText>
              } */}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p" component="p">
                Debes ingresar el último código que recibiste vía SMS en los siguientes campos, si no lo recibiste haz click en el botón para reenviarlo.
                    </Typography>
            </Grid>
            <Grid item xs={12}>
              <InputNip length={6} onChange={this.handleChange} id="firma-digital-codigo" disabled={this.state.continuar} />
            </Grid>
          </Grid>
        </CardElevation>
        <br />
        <Grid container>
          <Grid item style={{ textAlign: "right" }} xs={12}>
            <br />
            <ButtonDegrade id="firma-digital-continuar-button" disabled={!this.state.continuar} icon={MobileFriendly} textButton='CONTINUAR' onClick={this.eventoContinuar} />
          </Grid>
        </Grid>
        <Backdrop open={this.state.loading} /*css={backDropCss}*/ style={{ zIndex: '1000' }}>
          <CircularProgress color="primary" />
        </Backdrop>
        <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
          <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
            {this.state.mensaje}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.success} autoHideDuration={3000} onClose={() => this.setState({ success: false })}>
          <Alert variant="filled" severity="success" onClose={() => this.setState({ success: false })}>
            {this.state.mensaje}
          </Alert>
        </Snackbar>
      </>
    );
  }

}

export default VerificacionTelefonica;